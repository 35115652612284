<template>
  <div class="details-bg">
    <!-- <p class="tit">{{ $t("Details.table") }}</p> -->
    <!-- <p class="icon"></p> -->
    <p class="tip" style="font-size: 16px; margin-top: 40px">{{ $t("Details.tip") }}</p>
    <div class="info-buttons">
      <button class="button-column"
        @click="openInNewTab('https://docs.google.com/forms/d/e/1FAIpQLSePml-U15wjaq73fkn6b1_-6jptfbuLBaWvw9_txt7qV4TqmQ/viewform');">{{
          $t("Details.signup")
        }}</button>
      <button class="button-column" @click="download()">{{ $t("Details.download") }}</button>
      <button class="button-column" @click="handleClick()">{{
        $t("Details.event")
      }}</button>
      <p class="download">
        <span>{{ $t("Home.download_desc") }}</span>
        <span class="download_modle" @click="handleClickDownload()">{{ $t("Home.download_desc1") }}</span>
        <span>{{ $t("Home.download_desc2") }}</span>
      </p>
    </div>
    <h2>{{ $t('Details.header') }}</h2>
    <div>
      <div class="scope">
        <div class="content1">
          <div class="left">
            <p class="scope-tit">{{ $t("Details.scope") }}</p>
            <p class="count">1<span class="borbo"></span></p>
            <p class="tip">{{ $t("Details.scope1_tit") }}</p>
            <p class="txt">{{ $t("Details.scope1_txt") }}</p>
          </div>
          <div class="center">
            <p class="scope-tit">{{ $t("Details.scope") }}</p>
            <p class="count">2<span class="borbo"></span></p>
            <p class="tip">{{ $t("Details.scope2_tit") }}</p>
            <p class="txt">{{ $t("Details.scope2_txt") }}</p>
          </div>
          <div class="right">
            <p class="scope-tit">{{ $t("Details.scope") }}</p>
            <p class="count">3<span class="borbo"></span></p>
            <p class="tip">{{ $t("Details.scope3_tit") }}</p>
            <p class="txt">{{ $t("Details.scope3_txt") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="best-practice-box">
      <h3>{{ $t("Details.best_practice_title") }}</h3>
      <h3 style="font-weight: 1000">{{ $t("Details.best_practice_slogan") }}</h3>
      <p>{{ $t("Details.best_practice_content") }}</p>
    </div>
    <div class="judging-criteria-table">
      <p class="title">{{ $t("Details.title") }}</p>
      <p class="content">{{ $t("Details.success_recipient_1") }}</p>
      <p class="content">{{ $t("Details.success_recipient_2_1") }}<span style="font-weight: 700">{{
        $t("Details.success_recipient_2_2")
      }}</span>{{ $t("Details.success_recipient_2_3") }}</p>
    </div>
    <div class="table1">
      <table class="tab">
        <tr class="table-header">
          <td></td>
          <td></td>
          <td colspan="4" style="font-size:20px">{{ $t("Details.basic_scopes") }}</td>
          <td style="font-size:20px">{{ $t("Details.practices_showcase") }}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td style="position: relative">{{ $t("Details.table_header_1") }} <p class="borbo"></p>
          </td>
          <td style="position: relative">{{ $t("Details.table_header_2") }}<p class="borbo"></p>
          </td>
          <td style="position: relative">{{ $t("Details.table_header_3") }}<p class="borbo"></p>
          </td>
          <td><span style="font-weight: bolder">{{ $t("Details.table_header_4") }}</span></td>
          <td style="font-weight: bolder">{{ $t("Details.table_header_5") }}</td>
        </tr>
        <tr>
          <td rowspan="3" style="vertical-align: middle; padding-top: 10px;">{{ $t("Details.table_tit") }}</td>
          <td style="white-space: nowrap;">{{ $t("Details.employees1") }}</td>
          <td class="num">1<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num">1<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num">1<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num" style="font-weight: 900;">3<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="count">1<p class="borbo2"></p>
          </td>
        </tr>
        <tr>
          <td>{{ $t("Details.employees2") }}</td>
          <td class="num">1<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num">3<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num">2<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num" style="font-weight: 900;">6<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="count">1<p class="borbo2"></p>
          </td>
        </tr>
        <tr>
          <td>{{ $t("Details.employees3") }}</td>
          <td class="num">2<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num">4<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num">3<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="num" style="font-weight: 900;">9<p class="borle"></p>
            <p class="borbo2"></p>
          </td>
          <td class="count">2<p class="borbo2"></p>
          </td>
        </tr>
      </table>
    </div>
    <!-- <div class="table1">
        <p class="table-tit">
          {{ $t("Details.table_tit") }}
        </p>
        <table class="tab">
          <tr>
            <td></td>
            <td style="position: relative">
              {{ $t("Details.employees1") }}
  
              <p class="borbo"></p>
            </td>
            <td style="position: relative">
              {{ $t("Details.employees2") }}
              <p class="borbo"></p>
            </td>
            <td style="position: relative">
              {{ $t("Details.employees3") }}
              <p class="borbo"></p>
            </td>
          </tr>
          <tr>
            <td>{{ $t("Details.scope1") }}</td>
            <td>
              1
              <p class="borle"></p>
              <p class="borbo2"></p>
            </td>
            <td>
              1
              <p class="borle"></p>
              <p class="borbo2"></p>
            </td>
            <td>
              2
              <p class="borbo2"></p>
            </td>
          </tr>
          <tr>
            <td>{{ $t("Details.scope2") }}</td>
            <td>
              1
              <p class="borle"></p>
              <p class="borbo2"></p>
            </td>
            <td>
              3
              <p class="borle"></p>
              <p class="borbo2"></p>
            </td>
            <td>
              4
              <p class="borbo2"></p>
            </td>
          </tr>
          <tr>
            <td>{{ $t("Details.scope3") }}</td>
            <td>
              1
              <p class="borle"></p>
              <p class="borbo2"></p>
            </td>
            <td>
              2
              <p class="borle"></p>
              <p class="borbo2"></p>
            </td>
            <td>
              3
              <p class="borbo2"></p>
            </td>
          </tr>
          <tr>
            <td style="font-weight: 600">{{ $t("Details.total") }}</td>
            <td>
              3
              <p class="borle"></p>
            </td>
            <td>
              6
              <p class="borle"></p>
            </td>
            <td>9</td>
          </tr>
          <tr>
            <td style="width:100%" colspan="4">{{$t('Details.apply_detail')}}</td>
          </tr>
        </table>
      </div>  -->
    <!-- <p style="width: 70%; margin: 0 auto" v-html="$t('Details.txt1')"></p>
      <p v-html="$t('Details.txt2')"></p> -->
    <!-- <div class="table1 table2">
        <p class="table-tit">{{ $t("Details.table_tit") }}</p>
        <table class="tab">
          <tr>
            <td></td>
            <td style="position: relative">
              {{ $t("Details.employees1") }}
  
              <p class="borbo"></p>
            </td>
            <td style="position: relative">
              {{ $t("Details.employees2") }}
              <p class="borbo"></p>
            </td>
            <td style="position: relative">
              {{ $t("Details.employees3") }}
              <p class="borbo"></p>
            </td>
          </tr>
          <tr>
            <td class="td-tit">
              <p style="font-size: 18px" v-html="$t('Details.cofit1')"></p>
              <p
                style="
                  margin-top: 5px;
                  color: #535353;
                  font-size: 18px;
                  font-family: 'PingFang-Bold';
                  letter-spacing: 0px;
                "
              >
                {{ $t("Details.cofit2") }}
              </p>
            </td>
            <td>
              1
              <p class="borle borle2" style="right: -1px"></p>
            </td>
            <td>
              1
              <p class="borle borle2" style="right: -1px"></p>
            </td>
            <td>2</td>
          </tr>
        </table>
      </div>
      <div class="scope">
        <div class="content1">
          <div class="left">
            <p class="scope-tit">{{ $t("Details.scope") }}</p>
            <p class="count">1<span class="borbo"></span></p>
            <p class="tip">{{ $t("Details.scope1_tit") }}</p>
            <p class="txt">{{ $t("Details.scope1_txt") }}</p>
          </div>
          <div class="center">
            <p class="scope-tit">{{ $t("Details.scope") }}</p>
            <p class="count">2<span class="borbo"></span></p>
            <p class="tip">{{ $t("Details.scope2_tit") }}</p>
            <p class="txt">{{ $t("Details.scope2_txt") }}</p>
          </div>
          <div class="right">
            <p class="scope-tit">{{ $t("Details.scope") }}</p>
            <p class="count">3<span class="borbo"></span></p>
            <p class="tip">{{ $t("Details.scope3_tit") }}</p>
            <p class="txt">{{ $t("Details.scope3_txt") }}</p>
          </div>
        </div>
        <div class="scope-footer">
          <div class="tit">
            <p style="color: #535353; font-weight: 600">
              {{ $t("Details.scope_footer_tit1") }}
            </p>
            <p v-html="$t('Details.scope_footer_tit2')">}</p>
          </div>
          <p class="scope-line"></p>
          <p class="txt">{{ $t("Details.scope_footer_txt") }}</p>
        </div>
      </div> -->

    <!-- <div class="join">
        <div class="left">
          <p class="tit">{{ $t("Details.join_tit") }}</p>
          <div class="test"></div>
          <img
            class="img1"
            src="../assets/images/SportsHour_Logo_Company-01.png"
            alt=""
            width="320px"
            height="200px"
          />
          <img
            class="img2"
            src="../assets/images/Group 11738.svg"
            alt=""
            width="350px"
          />
          <a class="apply" target="_blank" href="/pdf/application_form_SportsHOUR_Company (1).pdf">{{ $t("Details.apply") }}<i class="sj"></i></a>
        </div>
  
        <div class="right"></div>
      </div> -->
    <!-- <p class="icon"></p>
      <p class="title">{{ $t("Details.join_date") }}</p>
      <p class="join-tip" v-html="$t('Details.join_tip')"></p>
      <div class="contact">
        <div class="adress">
          <i></i>
          <div>
            {{ $t("Details.address1") }}<br />{{ $t("Details.address2") }}
          </div>
        </div>
        <div class="email">
          <i></i>
          <div class="txt">{{ $t("Details.email") }}<i class="sj"></i></div>
        </div>
      </div> -->
  </div>
</template>
<script>
export default {
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    handleClick() {
      this.$router.push('/ceremony')
    },
    download() {
      let a = document.createElement('a');
      a.href = '/pdf/SportsHour Company Brochure.pdf';
      a.download = 'SportsHour Company Brochure.pdf';

      a.click();
    },
    handleClickDownload() {
      let a = document.createElement('a');
      a.href = '/pdf/SportsHourCompany_Online Brochure.pdf';
      a.download = 'SportsHourCompany_Online Brochure.pdf';

      a.click();
    },
    handleClickDownload() {
      let a = document.createElement('a');
      a.href = '/pdf/SHC application form.pdf';
      a.download = 'SHC application form.pdf';
      a.click();
    },
  }

};
</script>
<style scoped lang="scss">
@import "../style/enStyle.scss";

.details-bg {

  h2 {
    color: #707070;
    margin-bottom: 1em;
    font-size: 2.9em;
    font-family: 'Lato', sans-serif;
  }

  p {
    color: #707070;
  }

  .info-buttons {
    width: 60%;
    margin: 30px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    button {
      padding: 15px 10px;
      margin: 0 15px;
      color: white;
      background-color: #1900FF;
      border-radius: 5px;
      border-width: 0.1px;
      border-color: #1900FF;
      cursor: pointer;
      transition: 0.3s ease-in-out;
    }

    .download {
      margin-left: 1em;
      width: 80%;
    }

    button:hover {
      background-color: white;
      color: #1900FF;
    }
  }

  .download_modle {
    color: #4169E1;
    text-decoration: underline;
  }

  .best-practice-box {
    background-color: #1e30f6;
    padding: 50px 30px;
    width: 60%;
    margin: 30px auto 50px;
    border-radius: 10px;
    line-height: 200%;

    h3,
    p {
      color: white;
    }

    h3 {
      text-decoration: underline;
    }

    p {
      margin-top: 40px;
      font-size: 16px;
    }
  }

  .judging-criteria-table {
    padding: 30px;
    width: 60%;
    margin: 30px auto 10px;


    .title {
      text-align: center;
      margin-top: 0px;
    }

    p.content {
      text-align: left;
      margin-top: 20px;
      font-size: 16px;
      line-height: 150%;
    }


  }

  .count {
    font-size: 32px;
    color: #1e30f6;
    font-family: "PingFang-Heavy";
  }

  .tit {
    font-size: 20px;
    color: #6ec9f5;
    letter-spacing: 2px;
    margin: 30px auto;
    font-family: "PingFang-Heavy";
  }

  .icon {
    width: 40px;
    height: 40px;
    margin: 40px auto 5px;
    background: url("../assets/images/Group 11846.svg") 50%/50% no-repeat;
  }

  .title {
    font-size: 24px;
    color: #1e30f6;
    font-family: "PingFang-Heavy";
    letter-spacing: 2px;
    line-height: 24px;
  }

  .tip {
    width: 50%;
    margin: 30px auto;
    letter-spacing: 1px;
    color: #707070;
  }

  .table1 {
    width: 80%;
    // height: 400px;
    margin: 20px auto;
    font-size: 16px;
    color: #535353;
    border-radius: 20px;
    box-shadow: #efecef 2px 2px 8px 2px;
    padding: 20px;
    letter-spacing: 1px;

    .tab {
      width: 98%;
      margin: 0px auto;

      td {
        width: 85px;
        height: 70px;
        position: relative;
      }

      tr {
        td.num {
          color: #6ec9f5;
          font-size: 20px;
        }

        td.count {
          font-size: 24px;
          color: #1e30f6;
        }
      }

      // tr:not(:first-child) {
      //   td:not(:first-child) {
      //     // color: #6ec9f5;
      //     // font-size: 20px;
      //   }
      // }
      // tr:last-child {
      //   td:not(:first-child) {
      //     color: #1e30f6;
      //     font-family: "PingFang-Heavy";
      //   }
      // }
      .td-tit {
        width: 36%;
        padding-right: 100px;
      }
    }

    .borbo {
      position: absolute;
      left: 50%;
      bottom: 0px;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 5px;
      background: #6ec9f5;
      border-radius: 10px;
    }

    .borle {
      position: absolute;
      top: 12px;
      right: -2px;
      width: 1px;
      height: 40px;
      background: #e2e2e2;
    }

    .borbo2 {
      position: absolute;
      left: 50%;
      bottom: 0px;
      transform: translate(-50%, -50%);
      width: 102%;
      height: 2px;
      background: #e2e2e2;
      margin-bottom: 2px;
    }

    .borbo3 {
      position: absolute;
      left: 50%;
      bottom: 0px;
      transform: translate(-50%, -50%);
      width: 180px;
      height: 5px;
      background: #6ec9f5;
      border-radius: 10px;
    }
  }

  .table2 {
    width: 70%;
    height: 200px;

    .tab {
      tr:last-child {
        td:not(:first-child) {
          color: #6ec9f5;
          font-weight: normal;
        }
      }
    }
  }

  .table-tit {
    width: 98%;
    height: 40px;
    margin: 0px auto;
    font-size: 18px;
    border-bottom: 1px solid #e2e2e2;
  }

  .scale {
    height: 300px;
    margin: 20px auto 10px;
    width: 74%;
    background: url("../assets/images/Group 11748.png") no-repeat 100%/100%;
  }

  .scope {
    background: #6ec9f5;
    ;
    overflow: hidden;

    // height: 520px;
    .content1 {
      margin: 30px auto 30px;
      width: 65%;
      // color: #535353;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      letter-spacing: 1px;

      .left,
      .center,
      .right {
        height: 400px;
        border-radius: 20px;
        box-shadow: #efecef 1px 1px 1px 1px;
        background: #fff;
        margin: 0 25px;

        .scope-tit {
          margin: 35px auto;
          font-size: 15px;
          color: #535353;
          font-family: "PingFang-Heavy";
        }

        .count {
          font-size: 34px;
          position: relative;

          .borbo {
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 5px;
            background: #6ec9f5;
            border-radius: 10px;
          }
        }

        .tip {
          width: 100%;
          font-size: 16px;
          color: #6ec9f5;
        }

        .txt {
          margin: -10px auto;
          width: 80%;
          line-height: 25px;
        }
      }
    }

    .scope-footer {
      width: 73%;
      height: 100px;
      border-radius: 20px;
      box-shadow: #efecef 2px 2px 8px 2px;
      background: #fff;
      margin: 0px auto 50px;
      letter-spacing: 1px;

      .tit {
        // background: chocolate;
        width: 32%;
        float: left;
        text-align: center;
        line-height: 25px;
      }

      .scope-line {
        float: left;
        width: 20px;
        height: 3px;
        background: #6ec9f5;
        border-radius: 10px;
        display: table-cell;
        vertical-align: middle;
        margin-top: 50px;
      }

      .txt {
        // background: red;
        float: right;
        width: 60%;
        position: relative;
        top: 50%;
        /*偏移*/
        transform: translateY(-50%);
        margin-right: 4%;
      }
    }
  }

  .join {
    display: flex;
    height: 350px;
    position: relative;
    background: #f8f8f8;
    // .test {
    //   width: 100%;
    //   height: 100%;

    //   position: absolute;
    //   top: 0%;
    //   left: 0%;
    //   z-index: 1;
    //   // opacity: 0.5;
    // }
    .left {
      flex: 1;
      background: #6ec9f5;
      position: relative;

      .tit {
        top: 100px;
        right: 42px;
        font-size: 14px;
        position: absolute;
        color: #ffffff;
        z-index: 2;
      }

      .img1 {
        position: absolute;
        top: 80px;
        left: 0px;
        transform: translateX(-10px);
        opacity: 0.6;
      }

      // .img2 {
      //   position: absolute;
      //   top: 160px;
      //   right: 50px;
      //   z-index: 2;
      // }
      .apply {
        width: 320px;
        position: absolute;
        top: 170px;
        right: 50px;
        background: #1e30f6;
        color: #fff;
        padding: 18px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFang-Heavy;
        text-decoration: none;

        .sj {
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 6px solid #fff;
          border-bottom: 5px solid transparent;
          transform: translateX(60px);
        }
      }
    }

    .right {
      flex: 1;
      background: url("../assets/images/20210218-19.webp") no-repeat 100%/100%;
    }
  }

  .join-tip {
    margin: 30px auto 20px;
    font-size: 16px;
  }

  .contact {
    width: 55%;
    // height: 80px;
    margin: 2% auto 8%;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-family: "PingFang-Bold";

    // background: red;
    .adress {
      flex: 1;
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      background: #6ec9f5;
      line-height: 24px;
      padding: 20px 0px;

      i {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-left: 3%;
        background: url("../assets/images/adress.png") no-repeat 100%/70%;
      }

      div {
        margin-left: 6%;
      }
    }

    .email {
      flex: 1;
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      background: #1e30f6;
      padding: 20px 0px;

      i {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-left: 5%;
        background: url("../assets/images/email.png") no-repeat 100%/100%;
      }

      .txt {
        margin-left: 6%;
        display: flex;
        text-align: left;
        padding-bottom: 2%;
        border-bottom: 2px solid #6ec9f5;

        .sj {
          display: inline-block;
          width: 30px;
          height: 20px;
          transform: translateY(4px);
          background: url("../assets/images/Polygon 286.svg") no-repeat 100%/100%;
        }
      }
    }
  }
}


@media screen and (max-width: 850px) {
  .details-bg {
    .info-buttons {
      grid-template-columns: 1fr;
      width: 50%;

      button {
        margin-bottom: 10px;
      }
    }

    .scope {
      .content1 {
        grid-template-columns: 1fr;

        .left,
        .center,
        .right {
          height: 260px;
          margin: 10px;

          .scope-tit {
            margin: 20px auto;
          }
        }
      }
    }

    .table1 {
      width: 60%;
      // height: 400px;
      font-size: 14px;
      // box-shadow: #efecef 2px 2px 8px 2px;
      padding: 14px;
      letter-spacing: 0px;

      .tab {

        .table-header td {
          font-size: 16px !important;
        }

        tr {
          td.num {
            color: #6ec9f5;
            font-size: 16px;
          }

          td.num2 {
            color: #1900ff;
            font-size: 16px;
          }
        }
      }
    }
  }


}

@media screen and (max-width: 500px) {
  .details-bg {
    .tip {
      width: 90%;
    }

    .best-practice-box {
      background-color: #1e30f6;
      padding: 50px 30px;
      width: 85%;
      margin: 30px auto 50px;
      border-radius: 10px;
      line-height: 200%;

      h3,
      p {
        color: white;
      }

      h3 {
        text-decoration: underline;
      }

      p {
        margin-top: 40px;
        font-size: 16px;
      }
    }

    .judging-criteria-table {
      padding: 30px;
      width: 85%;
      margin: 30px auto 10px;


      .title {
        text-align: center;
        margin-top: 0px;
      }

      p.content {
        text-align: left;
        margin-top: 20px;
        font-size: 16px;
        line-height: 150%;
      }


    }

    .table1 {
      width: 85%;
      overflow-x: auto;

      .borbo {
        width: 90%;
        bottom: -10px;
      }

      .tab {
        width: 100%;

        .td-tit {
          width: 20%;
          padding-right: 0px;
        }
      }

      .borle2 {
        top: 60px;
      }
    }

    .table2 {
      width: 80%;
      height: auto;
    }

    .scope {
      .content1 {
        width: 90%;
        flex-direction: column;

        .center,
        .right,
        .left {
          height: auto;
          margin: 0;
          margin-top: 4%;
          padding: 0% 0 5%;
        }
      }

      .scope-footer {
        width: 90%;
        height: 160px;

        .txt {
          top: 20%;
          transform: translateY(0%);
          margin-right: 1%;
        }
      }
    }

    .join {
      height: 550px;
      flex-direction: column;

      .left {
        .img1 {
          width: 100%;
          top: 0%;
        }

        .tit {
          top: auto;
          right: 0;
          bottom: 15%;
          width: 100%;
        }

        .apply {
          top: auto;
          right: 0;
          bottom: 0;
          width: 100%;
        }
      }

      .right {
        background: url("../assets/images/20210218-19.webp") no-repeat 20%/150%;
      }
    }

    .join-tip {
      width: 90%;
    }

    .contact {
      width: 100%;
      flex-direction: column;
    }
  }
}</style>
